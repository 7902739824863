import Homepage from "./Homepage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AdminPage from "./Admin";

import {
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";

const createApolloClient = (authToken) => {
  return new ApolloClient({
    link: new HttpLink({
      uri: "https://fmgroove.hasura.app/v1/graphql",
      headers: {
        "x-hasura-admin-secret": authToken,
      },
    }),
    cache: new InMemoryCache(),
  });
};

const App = ({ idToken }) => {
  console.table({ idToken });
  const client = createApolloClient(idToken);
  return (
    <ApolloProvider client={client}>
      <Router>
        <Switch>
          <Route path="/admin">
            <AdminPage />
          </Route>
          <Route path="/">
            <Homepage />
          </Route>
        </Switch>
      </Router>
    </ApolloProvider>
  );
};

export default App;
