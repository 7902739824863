import { useState } from "react";
import Cookies from "js-cookie";

import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

import { useQuery, gql, useMutation } from "@apollo/client";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 3,
};

const GET_SUBMITTED_EMAILS = gql`
  query MyQuery {
    emails {
      id
      email
    }
  }
`;

const DELETE_EMAIL = gql`
  mutation MyMutation($id: Int!) {
    delete_emails(where: { id: { _eq: $id } }) {
      affected_rows
    }
  }
`;

const cookieLastsInDays = 30;

const getCookieValue = () => {
  return Cookies.get("isLoggedIn");
};

const setCookieValue = (value) => {
  Cookies.set("isLoggedIn", value, { expires: cookieLastsInDays });
};

const LoginForm = ({ onSuccessfulLogin }) => {
  const [userEntry, setUserEntry] = useState("");
  const [shouldRemember, setShouldRemember] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const onSubmit = () => {
    console.table({ userEntry });
    if (userEntry === "fmgroove") {
      if (shouldRemember) {
        setCookieValue(1);
      }
      onSuccessfulLogin();
    } else {
      setErrMsg("Wrong Password");
      setTimeout(() => {
        setErrMsg("");
      }, 2000);
    }
  };

  return (
    <div
      style={{
        width: "25rem",
        height: "16.5rem",
        border: "1px solid lightgrey",
        borderRadius: "10px",
      }}
    >
      <h2 style={{ textAlign: "center", marginBottom: "0.8rem" }}>
        Log-In Form
      </h2>
      <p
        style={{
          color: "red",
          margin: "0",
          textAlign: "center",
          height: "1rem",
        }}
      >
        {errMsg}
      </p>
      <input
        onChange={(e) => {
          setUserEntry(e.target.value);
        }}
        type="password"
        placeholder="password"
        style={{
          border: "none",
          borderBottom: "1px solid gray",
          margin: "1rem 2rem",
          fontSize: "1.1rem",
          width: "calc(100% - 4rem)",
          padding: "0.4rem 0",
        }}
        onKeyPress={(e) => e.key === "Enter" && onSubmit()}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <button className="login-form__button" onClick={onSubmit}>
          Submit
        </button>
      </div>
      <div style={{ margin: "1rem" }}>
        <input
          type="checkbox"
          id="remember_me"
          name="remember_me"
          value="remember_me"
          onChange={(e) => setShouldRemember(e.target.checked)}
        />
        <label
          htmlFor="remember_me"
          style={{ color: "gray", marginLeft: "0.5rem" }}
        >
          Remember me on this computer for {cookieLastsInDays} days
        </label>
      </div>
    </div>
  );
};

const LoginBody = ({ onSuccessfulLogin }) => {
  return (
    <div
      style={{
        width: "100vw",
        height: "calc(100vh - 4rem)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoginForm onSuccessfulLogin={onSuccessfulLogin} />
    </div>
  );
};

const EmailListItem = ({ email, onClickDelete }) => {
  return (
    <li className="email-list__list-item">
      <span>{email}</span>
      <button
        onClick={onClickDelete}
        className="email-list__cancel-btn"
        style={{
          userSelect: "none",
          float: "right",
          fontWeight: 700,
          fontSize: "0.7rem",
          border: "none",
          backgroundColor: "darkgray",
          color: "white",
          borderRadius: "3px",
        }}
      >
        X
      </button>
    </li>
  );
};

const AdminPage = () => {
  const [selectedId, setSelectedId] = useState(0);
  const [isLoggedIn, setIsLoggedIn] = useState(!!getCookieValue());
  const [emails, setEmails] = useState([]);
  const { loading, error, data } = useQuery(GET_SUBMITTED_EMAILS);
  const [deleteEmail] = useMutation(DELETE_EMAIL);
  const [hasLoaded, setHasLoaded] = useState(false);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedId(0);
    setOpen(false);
  };

  let pageBody, emailList;

  if (!isLoggedIn) {
    pageBody = <LoginBody onSuccessfulLogin={() => setIsLoggedIn(true)} />;
  } else {
    if (loading) {
      pageBody = (
        <div
          style={{
            width: "100vw",
            height: "calc(100vh - 4rem)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h1 style={{ position: "relative", bottom: "2rem" }}>Loading...</h1>
        </div>
      );
    }
    if (error) {
      console.error(error);
      pageBody = <div>Error!</div>;
    } else if (data) {
      if (!hasLoaded) {
        setHasLoaded(true);
        setEmails(data.emails);
      }
    }

    if (!hasLoaded) {
      emailList = <p style={{ marginBottom: "0.4rem" }}>Loading...</p>;
    } else if (emails) {
      emailList = emails.length ? (
        <ul>
          {emails.map((obj) => {
            return (
              <EmailListItem
                email={obj.email}
                onClickDelete={() => {
                  setSelectedId(obj.id);
                  handleOpen();
                }}
              />
            );
          })}
        </ul>
      ) : (
        <p style={{ marginBottom: "0.4rem" }}>No emails submitted yet.</p>
      );
    }

    pageBody = (
      <div
        style={{
          marginTop: "0.7rem",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            maxWidth: "650px",
            border: "1px solid #dbeeff",
            padding: "1rem",
            borderRadius: "5px",
            margin: "0 1rem 1rem 1rem",
          }}
        >
          <p style={{ fontWeight: 700 }}>Submitted emails</p>
          {emailList}
        </div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <h1 style={{ marginTop: 0, textAlign: "center" }}>Delete Email?</h1>
            <div
              style={{
                margin: "1rem",
                marginTop: "2.5rem",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "10rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <button
                  onClick={() => {
                    deleteEmail({ variables: { id: selectedId } });
                    const newEmails = [...emails].filter(
                      (emailObj) => emailObj.id !== selectedId
                    );
                    console.log("newEmails");
                    console.log(newEmails);
                    setEmails(newEmails);
                    handleClose();
                  }}
                >
                  Delete
                </button>
                <button onClick={() => handleClose()}>Cancel</button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    );
  }

  return (
    <div>
      <div
        style={{
          height: "4rem",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <h1 style={{ textAlign: "center", margin: 0 }}>Admin Page</h1>
      </div>
      <div>{pageBody}</div>
    </div>
  );
};

export default AdminPage;
