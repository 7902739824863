import { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";

import bkdg from "./images/bkdg.low_res.jpg";
import "./App.css";

import { gql } from "@apollo/client";
import { useMutation } from "@apollo/client";

import { debounce } from "debounce";

const ADD_EMAIL = gql`
  mutation MyMutation($email: String!) {
    insert_emails(objects: { email: $email }) {
      affected_rows
    }
  }
`;

const originalAspectRatio = 900 / 536;
const imageClasses = {
  desktop: "bkgd_image--desktop",
  mobile: "bkgd_image--mobile",
};

const Homepage = () => {
  const [submittedEmails, setSubmittedEmails] = useState([]);
  const [email, setEmail] = useState("");
  const [imgClass, setImgClass] = useState(imageClasses.desktop);
  const [addEmail] = useMutation(ADD_EMAIL);
  const [isLoading, setIsLoading] = useState(true);

  const getWindowDimensions = () => {
    return [window.innerWidth, window.innerHeight];
  };

  useEffect(() => {
    const getResponsiveImageClass = () => {
      const [width, height] = getWindowDimensions();
      const aspectRatio = width / height;

      const retClass =
        aspectRatio >= originalAspectRatio
          ? imageClasses.desktop
          : imageClasses.mobile;

      console.table({ retClass });

      return retClass;
    };

    const setResponsiveImageClass = () => {
      const imgClass = getResponsiveImageClass();
      setImgClass(imgClass);
    };

    const resize = () => {
      setResponsiveImageClass();
      console.table({ dim: getWindowDimensions().toString() });
    };

    setResponsiveImageClass();
    window.onresize = debounce(resize, 100);
  }, []);

  const flashMessageInTextbox = ({ input, isError, msg }) => {
    setImmediate(() => {
      if (isError) input.style.color = "red";
      input.value = msg;
    });
    setTimeout(() => {
      input.value = "";
      if (isError) {
        input.style.removeProperty("color");
      } else {
        setEmail("");
      }
    }, 2000);
  };

  const handleSubmitClick = () => {
    const input = document.getElementById("email-form__input");

    if (email && submittedEmails.indexOf(email) === -1) {
      addEmail({ variables: { email } });
      setSubmittedEmails([...submittedEmails, email]);
      flashMessageInTextbox({ input, isError: false, msg: "Email submitted!" });
    } else if (!email) {
      flashMessageInTextbox({
        input,
        isError: true,
        msg: "Please enter something first",
      });
    } else {
      flashMessageInTextbox({
        input,
        isError: true,
        msg: "Email already submitted!",
      });
    }
  };

  const imgStyles = {
    isLoading: {
      visibility: "hidden",
    },
    hasLoaded: {
      visibility: "visible",
    },
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        minHeight: "523px",
        overflow: "hidden",
        position: "relative",
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
      }}
    >
      <img
        className={imgClass}
        onLoad={() => {
          console.log("has loaded");
          setIsLoading(false);
        }}
        alt={"Francis rocking the mic"}
        style={
          isLoading
            ? { ...imgStyles["isLoading"], position: "absolute" }
            : { ...imgStyles["hasLoaded"], position: "absolute" }
        }
        src={bkdg}
      />
      {isLoading ? (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BounceLoader color="darkgray" />
        </div>
      ) : (
        <main
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            position: "relative",
          }}
        >
          <h1
            style={{
              fontSize: "2.4rem",
              position: "absolute",
              left: "0.8rem",
              top: "0.3rem",
              margin: "0",
              color: "white",
            }}
          >
            FMGroove.com
          </h1>
          <div
            className="center_panel"
            style={{
              position: "absolute",
              top: "40%",
              maxWidth: "360px",
              margin: "0.7rem",
            }}
          >
            <div
              className="center_panel__label"
              style={{
                width: "100%",
                height: "140px",
                padding: "0 2rem",
                backgroundColor: "rgba(0,0,0,0.85)",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <h1
                style={{
                  color: "white",
                  margin: 0,
                  fontFamily: "impact",
                  fontSize: "1.7rem",
                }}
              >
                Join Francis Mbappe's
              </h1>
              <h1
                style={{
                  color: "white",
                  margin: 0,
                  fontFamily: "impact",
                  fontSize: "1.7rem",
                  // paddingLeft: "2rem",
                }}
              >
                Mailing List
              </h1>
            </div>

            <input
              value={email}
              id="email-form__input"
              onChange={(e) => setEmail(e.target.value)}
              onKeyDown={(e) => e.key === "Enter" && handleSubmitClick()}
              placeholder="Please enter your email address"
              style={{
                width: "100%",
                fontSize: "0.9rem",
                marginTop: "1.2rem",
                border: "1px solid lightgrey",
                padding: "0.7rem 1rem",
                borderRadius: "3px",
              }}
              type="text"
            />
            <button
              className="center_panel__submit"
              style={{ fontSize: "1rem" }}
              onClick={handleSubmitClick}
            >
              Sign up!
            </button>
          </div>
        </main>
      )}
    </div>
  );
};

export default Homepage;
